<template>
    <CRow class="ml-3 mr-3">
        <CCol lg="12">
            <div class="form-group"> 
                <label for="assigned_personnel"> {{title}}
                </label> 
                <CInputRadioGroup  
                    :options="[
                    { 
                            value: 'direct_hire', 
                            label: 'Direct Hired'
                        },
                        { 
                            value: 'outsourced', 
                            label: 'Outsourced'
                        },
                        
                    ]" 
                    :checked.sync="assigned_personnel" 
                    :inline="true" 
                    :required="required"
                />
            </div>
        </CCol>  
    </CRow>	
</template>
<script>

export default {
    name : 'AssignedPersonnelTemplate',
    props: ['title','value','required'],
    data() {
        return {
            assigned_personnel : 'direct_hire'
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.assigned_personnel = this.value;
            console.log('tick', this.value)
        })
        
    },
    watch: {
        value : function(val) {
            console.log('2',val)
            this.assigned_personnel = val;
        },
        assigned_personnel(val) {
            console.log('1', val)
            this.$emit('changed', val)
        }
    }
}
</script>
