var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',{staticClass:"ml-3 mr-3"},[_c('CCol',{attrs:{"lg":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"assigned_personnel"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('CInputRadioGroup',{attrs:{"options":[
                { 
                        value: 'direct_hire', 
                        label: 'Direct Hired'
                    },
                    { 
                        value: 'outsourced', 
                        label: 'Outsourced'
                    } ],"checked":_vm.assigned_personnel,"inline":true,"required":_vm.required},on:{"update:checked":function($event){_vm.assigned_personnel=$event}}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }